<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>ARS예약콜 관리</h1>
            <div class="is-right">
            </div>
        </div>

        <!--조회-->
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="40px">
                    <col width="250px">
                    <col width="70px">
                    <col width="125px">
                    <col width="125px">
                    <col width="110px">
                    <col width="80px">
                    <col width="100px">
                    <col>
                </colgroup>
                <tr>
                    <th><label>접수일</label></th>
                    <td>
                    <div class="jh-cols">                        
                        <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                            maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                        </template>
                        <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                        </v-menu>
                        <span class="jh-unit">~</span>                       
                        <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                            maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                        </template>
                        <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                        </v-menu>
                    </div>
                    </td>
                    <th><label>담당자</label></th>
                    <td><v-select class="jh-form" :items="ATTR_A_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="ATTR_A"></v-select></td>
                    <td><v-select class="jh-form" :items="ATTR_B_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="ATTR_B"></v-select></td>
                    <td><v-select class="jh-form" :items="USER_COMBO" item-text="USER_NM" item-value="USER_ID" placeholder="선택" v-model="CSLT_MAN"></v-select></td>
                    <th><label>처리상태</label></th>
                    <td><v-select class="jh-form" :items="RSV003_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="PROC_ST_CD"></v-select></td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="selectbutton">조회</v-btn></td>
                </tr>
            </table>
        </div>
        <!--//조회-->

        <!--리스트-->
        <div class="jh-form-wrap">
            <div class="jh-ui-header">
                <h2>ARS예약 리스트</h2>
                <div class="is-right">
                    <v-btn class="jh-btn is-main" @click="showDialog('P10180101')">배분</v-btn>
                    <v-btn class="jh-btn is-del" @click="redistConfirm()">배분회수</v-btn>
                    <span class="jh-split"></span>
                    <v-btn class="jh-btn is-light" @click="excelDownload('divGridARSRsvList', 'ARS예약관리 '+TODAY)">엑셀다운로드</v-btn>
                </div>
            </div>

            <data-tables
            ref="dataTable"
            id="divGridARSRsvList"
            dataTableClass="has-control"
            :data-table-options="dataTableOptions"
            @click:row="onClickRow"
            :paginationOptions="paginationOptions"
            >
                <template v-slot:item.CUST_NM="{ item }">
                    <span v-if="item.CUST_NO == '' && item.ACCOUNT == ''">미인증고객</span>
                    <span v-else>{{ item.CUST_NM }}</span>
                </template>
            </data-tables>
        </div>
        <!--//리스트-->

        <!--상세-->
        <div class="jh-form-wrap">
            <div class="jh-ui-header">
                <h2>ARS예약 상세정보</h2>
                <!-- <div class="is-right">
                    <v-btn class="jh-btn is-main" @click="saveDetail">저장</v-btn>
                </div> -->
            </div>
            <table class="jh-tbl-detail">
                <colgroup>
                    <col width="100px">
                    <col width="180px">
                    <col width="100px">
                    <col>
                </colgroup>
                <tbody>
                    <tr>
                        <th><label>처리상태</label></th>
                        <td> 
                            <span class="is-value">{{detailObj.PROC_ST}}</span>
                        </td>
                        <th rowspan="3"><label>상담내용</label></th>
                        <td rowspan="3" class="is-p-0"> 
                            <textarea class="jh-form is-trns" style="height: 89px;" v-model="detailObj.CNSL_CNTN" readonly></textarea>
                        </td>
                    </tr>
                    <tr>
                        <th><label>처리자</label></th>
                        <td> 
                            <span class="is-value" >{{detailObj.PROC_MAN}}</span>
                        </td>
                    </tr>
                    <tr>
                        <th><label>처리일시</label></th>
                        <td> 
                            <span class="is-value">{{detailObj.PROC_DT_TIME}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--상세-->
        <v-dialog max-width="1000" v-model="dialogP10180101">
            <dialog-P10180101
                @hide="hideDialog('P10180101')"
                :param="param"
                :histNos="scdlIds"
                @status="setStatus"
                @selectUser="[hideDialog('P10180101'), setUser()]"
            ></dialog-P10180101>
            <template v-slot:body></template>
        </v-dialog>
    </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import dialogP10180101 from "./P10180101.vue";

export default {
    name: "MENU_E010301", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        dialogP10180101,
        dataTables
    },
    data() {
        return {
            dialogP10180101: false,
            menuStartDate: false,
            menuEndDate: false,
            TODAY: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),        
            from: moment().format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
            dates: [
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            ],
            headers: [
                { text: 'NO', value: 'ROWNUM', align: 'center', width: '40px', },
                { text: '접수일시', value: 'RCPT_DT_TIME', align: 'center', width: '200px',},
                { text: '계좌번호', value: 'ACCOUNT', align: 'center', width: '200px', },
                { text: '고객명', value: 'CUST_NM', align: 'center', width: '150px', },
                { text: '전화번호', value: 'TNO', align: 'center', width: '200px', },
                { text: '업무유형', value: 'CNSL_TYPE', },
                { text: '처리상태', value: 'PROC_ST', align: 'center', width: '100px', },
                { text: '담당자', value: 'CSLT_MAN', align: 'center', width: '150px', },
                { text: '통화일시', value: 'TEL_DT_TIME', align: 'center', width: '200px', },
                { text: '통화결과', value: 'TEL_RSLT_CD_NM', align: 'center', width: '100px', },
            ],
            selectedList:[],
            dataTableOptions: {
                fixedHeader: true,
                hideDefaultFooter: true,
                height: '430px',
                items: undefined,
                itemKey: 'ROWNUM',
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                showSelect: true,
                itemClass: "[item.selected && 'selected']",
                load: undefined,
            },
            paginationOptions: {
                totalVisible: 10
            },
            alertMsg: {
                saveDetail: '저장하시겠습니까?'
            },
            param: {
                page: 'E010301',
                type: 'ars'
            },

            // 콤보박스
            RSV003_COMBO: [{ CD: '', CD_NM: '전체' }], // 처리상태
            ATTR_A_COMBO: [], // 대분류
            ATTR_B_COMBO: [], // 중분류
            USER_COMBO: [], // 담당자선택

            // 조회조건
            CSLT_MAN: '',   // 담당자
            PROC_ST_CD: '',    // 처리상태

            // 상세내용
            detailObj: {},

            scdlIds: '',
            status: '',

            // 콤보박스 선택값
            ATTR_A: '',
            ATTR_B: '',

            selectedRow: [],
            message: '',
        }
    },
    computed: {
        rowNum() {
            return this.items?.map(
                (items, index) => ({
                ...items,
                index: index + 1
            }))
        },
        dataTableInstance: function() {
            return this.$refs.dataTable;
        },     
        initHeaders(){
            return {
                SERVICE: "phone.ars",
                METHOD: "",
                TYPE: 'BIZ_SERVICE',
            };
        },
    },
    created() { 
        this.dataTableOptions.headers = this.headers;
        this.dataTableOptions.items = this.rowNum;
    },
    mounted() {
        this.getSelBoxList();
        this.getAttrList();
    },
    watch: {
        "ATTR_A": async function (val) {
            this.CSLT_MAN = '';
            this.ATTR_B = '';
            if (val == '') {
                this.ATTR_B_COMBO = [{ CD: '', CD_NM: '전체' }];
            } else {
                this.ATTR_B_COMBO = await this.mixin_get_attr_by_attr('', val);
            }
        },
        "ATTR_B": async function (val) {
            this.CSLT_MAN = '';
            this.USER_COMBO = await this.mixin_get_user_by_attr(this.ATTR_A, val);
        },
        "status": async function (val) {
            if (val === 'end') {
                this.dataTableInstance.loadData();
                this.detailObj = {};
                this.status = '';
            }
        }
    },
    methods: {
        fromOnInput() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
                // 종료일자보다 이후를 입력했으면 종료일자로 변경
                if (moment(this.from).isAfter(this.dates[1])) {
                    this.$nextTick(() => {
                        this.from = this.dates[1];
                    });
                }

                this.$nextTick(() => {
                    this.dates[0] = this.from;
                    // datepick 다시 랜더링
                    this.menuStartDate = false;
                    this.menuStartDate = true;
                });
            }
        },
        changeDate(type) {
            if(type == 'start') {
                // if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(90, 'days').format('YYYY-MM-DD'))) {
                //     this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                //     const date = moment().format("YYYY-MM-DD");
                //     this.dates[0] = date;
                //     this.from = date;
                //     return;
                // } else {
                    this.from = this.dates[0];
                // }
            } else if (type == 'end') {
                // if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(90, 'days').format('YYYY-MM-DD'))) {
                //     this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                //     const date = moment().format("YYYY-MM-DD");
                //     this.dates[1] = date;
                //     this.to = date;
                //     return;
                // } else {
                    this.to = this.dates[1];
                // }
            }
        },
        startDate(e) {
            this.from = e;
        },
        toOnInput() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
                // 시작일자보다 이전을 입력했으면 시작일자로 변경
                if (moment(this.to).isBefore(this.dates[0])) {
                    this.$nextTick(() => {
                        this.to = this.dates[0];
                    });
                }

                this.$nextTick(() => {
                    this.dates[1] = this.to;
                    // datepick 다시 랜더링
                    this.menuEndDate = false;
                    this.menuEndDate = true;
                });
            }
        },
        endDate(e) {
            this.to = e;
        },
        onClickRow(item, row) { 
            // row.select(true);
            // row.isSelected = true;
            this.selectedRow.push(row);
            this.detailObj = item;
        },
        showDialog(type) {
            //팝업 파라미터 설정
            this.popupParams;
            if (this.validDist()) {
                this.scdlIds = this.getScdlIds();
                this[`dialog${type}`] = true;
            } else {
                this.common_alert(this.message, 'noti');
            }
        },
        hideDialog(type) {
            this[`dialog${type}`] = false;
        },
        submitDialog(type){
            console.log('완료');
            this[`dialog${type}`] = false
        },
        selectbutton() {

            this.dataTableOptions.load = this.selectArsWork;

            if (this.dataTableOptions.page > 1) {
                this.dataTableOptions.page = 1;
            } else {
                this.dataTableInstance.loadData();
            }
        },
        async selectArsWork(loadOptions) {

            // if (this.selectedRow.length > 0) {
            //     this.selectedRow.forEach(item => {
            //         item.select(false);
            //     });
            // }

            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            const sortBy = loadOptions?.sortBy?.[0];
            const sortDesc = loadOptions?.sortDesc?.[0] === undefined ? 'desc' : loadOptions?.sortDesc?.[0] ? 'desc' : 'asc';

            requestData.headers["URL"] = "/api/phone/ars/list";
            requestData.headers["METHOD"] = "select";
            requestData.headers["ASYNC"] = false;
            requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            requestData.headers["PAGES_CNT"] = loadOptions.page; 

            requestData.sendData["STR_RECP_DT"] = this.dates[0].replace(/\-/g, "");
            requestData.sendData["END_RECP_DT"] = this.dates[1].replace(/\-/g, "");
            requestData.sendData["CSLT_MAN"] = this.CSLT_MAN;
            requestData.sendData["PROC_ST_CD"] = this.PROC_ST_CD;
            requestData.sendData["USER_ATTR_A"] = this.ATTR_A;
            requestData.sendData["USER_ATTR_B"] = this.ATTR_B;
            requestData.sendData["SORT_ORDR"] = sortBy ? sortBy : 'ROWNUM';
            requestData.sendData["SORT_DRCT"] = sortDesc;

            const response = await this.common_postCall(requestData);
            this.detailObj = {};

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                let header = response.HEADER;
                let data = response.DATA;

                data.forEach(item => {
                    item.ACCOUNT = item.ACCOUNT.indexOf('-') < 0 ? 
                        this.mixin_accNoFormat(item.ACCOUNT) : this.mixin_accNoFormat(item.ACCOUNT.replace(/\-/g, ''));
                    item.TNO = item.TNO.indexOf('-') < 0 ? this.mixin_telNumFormat(item.TNO) : item.TNO;
                });

                return {
                    data: data,
                    totalCount: header.TOT_COUNT
                };
            }
        },
        async getSelBoxList() {
            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            requestData.headers["URL"] = "/api/code/common/code-book/selectCode";
            requestData.headers["METHOD"] = "selectCode";
            requestData.headers["ASYNC"] = false;

            let sendList = [];
            sendList.push("RSV003"); //처리결과코드

            requestData.sendData["GROUP_CD"] = sendList

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {

                for (var i = 0; i < response.DATA.length ; i++){
                    if (response.DATA[i].GROUP_CD == "RSV003"){
                        this.RSV003_COMBO.push(response.DATA[i]);
                    }
                }
            }
        },
        redistConfirm() {
            if (this.validDist('회수')) {
                this.common_confirm('담당자를 배분회수 하시겠습니까?', this.updateRedist, null, null, null, "noti");
            } else {
                this.common_alert(this.message, 'noti');
            }
        },
        async updateRedist() {

            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            let scdlIds = this.getScdlIds();

            requestData.headers["URL"] = "/api/phone/ars/up-redist";
            requestData.headers["METHOD"] = "update";
            requestData.headers["ASYNC"] = false;

            requestData.sendData["SCDL_ID"] = scdlIds;
            requestData.sendData["CSLT_MAN"] = '';  // 담당자
            requestData.sendData["UPD_ID"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                this.common_alert(`정상적으로 배분회수되었습니다.`, 'done')
                this.dataTableInstance.loadData();
            }
        },
        getScdlIds() {
            let scdlIds = '';

            let obj = JSON.stringify(this.dataTableInstance.getSelectedData());
            let obj2 = JSON.parse(obj);

            const arr = [];

            Object.keys(obj2).map(item => {
                arr.push(obj2[item].SCDL_ID)
            })

            for(let i in arr) {
                scdlIds += arr[i] + "/";
            }

            return scdlIds;
        },

        excelDownload(table_id, file_name) {
            //number,date,time,string - n,d,t,s, dt : yyyy-mm-dd h:mm , dts: yyyy-mm-dd h:mm:ss
            var exceltypeArr = {};
            exceltypeArr[0] = "n"; 
            exceltypeArr[1] = "s"; 
            exceltypeArr[2] = "s"; 
            exceltypeArr[3] = "s"; 
            exceltypeArr[4] = "s"; 
            exceltypeArr[5] = "s"; 
            exceltypeArr[6] = "s"; 
            exceltypeArr[7] = "s"; 
            exceltypeArr[8] = "s"; 
            exceltypeArr[9] = "s"; 


            this.table_id = table_id;
            this.file_name = file_name;
            this.mixin_common_exportExcel(exceltypeArr);
        },

        async getAttrList() {
            this.ATTR_A_COMBO = await this.mixin_get_attr_by_attr('****', '');
        },

        setStatus() {
            this.status = 'end';
        },

        validDist(param) {
            let result = true;

            let obj = JSON.stringify(this.dataTableInstance.getSelectedData());
            let obj2 = JSON.parse(obj);

            if (Object.keys(obj2).length > 0) {
                Object.keys(obj2).map(item => {
                    if (obj2[item].PROC_ST_CD == '02' || obj2[item].PROC_ST_CD == '03') {
                        result = false;
                        if(param == undefined) param = '';
                        this.message = '처리상태가 처리중 또는 처리완료인 상담은<br/>배분'+param+' 할 수 없습니다.';
                    }
                })
            } else {
                result = false;
                this.message = '선택된 항목이 없습니다.';
            }

            return result;
        },
    },
};
</script>

<style></style>  